import apiCall from 'app/libs/utils/api/apiCall';
import { buildUrl } from 'libs/utils/api';
import { OrderTotalsHash, LineItemResponse, Order } from './types';
import { ApiResponse, SharedErrors } from 'libs/utils/api/types';

// /orders
export const ordersScope = (path?) => `/orders${path || ''}`;

// GET /cart
export const getOrderFromToken = (orderNumber, authToken): ApiResponse<Order, SharedErrors> =>
  apiCall.get<Order, SharedErrors>({ url: `/orders/${orderNumber}?auth_token=${authToken}`, unscoped: true });

// POST /orders/populate
export const populateOrder = (quoterJson, alias): ApiResponse<LineItemResponse, SharedErrors> => {
  const url = buildUrl(ordersScope('/populate'));
  return apiCall.post<LineItemResponse, SharedErrors>({ url, unscoped: true, data: { quoterJson, alias } });
};

export const updateLineItem = (lineItemId, quoterJson): ApiResponse<LineItemResponse, SharedErrors> => {
  const url = buildUrl(ordersScope(`/edit_line_item?line_item_id=${lineItemId}`));
  return apiCall.patch<LineItemResponse, SharedErrors>({
    url,
    unscoped: true,
    data: {
      quoterJson: JSON.stringify(quoterJson),
    },
  });
};

export const removeLineItem = (lineItemId): ApiResponse<OrderTotalsHash, SharedErrors> => {
  const url = buildUrl(ordersScope(`/remove_line_item?line_item_id=${lineItemId}`));
  return apiCall.delete<OrderTotalsHash, SharedErrors>({
    url,
    unscoped: true,
  });
};
