import apiCall from 'app/libs/utils/api/apiCall';
import { SharedErrors } from 'libs/utils/api/types';
import { Order } from 'api/orders/types';

// orders/:order_number/payments/
export const paymentsScope = (orderNumber, path?) => `/orders/${orderNumber}/payments${path || ''}`;

// orders/:order_number/payments
export const createPayment = (orderNumber, authToken, paymentAttributes) => {
  const url = paymentsScope(orderNumber, `?auth_token=${authToken}`);
  return apiCall.post<Order, SharedErrors>({ url, data: paymentAttributes });
};
